//1.导入vue和router包
import Vue from 'vue'
import VueRouter from 'vue-router'

// import ShowPdf from '../components/ShowPdf.vue'                       //需要跳转的vue文件     
import Login from '../components/Login.vue'                       //需要跳转的vue文件  
import ShowInfo from '../components/ShowInfo.vue'                       //需要跳转的vue文件    

//2.调用Vue.use()函数，把 VueRouter 安装为 Vue 的插件
Vue.use(VueRouter)

//3.创建路由的示例对象
const router = new VueRouter({
  routes:[
    //在 routes 数组中，申明路由的匹配规则
    //path 表示要匹配的 hash 地址；component 表示要展示的路由组件
    { path:'/',redirect: "/login"},
    { path:'/login',component:Login},
    { path:'/ShowInfo',component:ShowInfo},
    // { path:'/ShowPdf',component:ShowPdf},
    { path:'/ShowPdf',redirect: 'https://www.example.com/home.html'},
  ]
})
//4.向外共享路由示例对象
export default router
