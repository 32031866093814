import Vue from 'vue'
import App from './App.vue'
// 新增加 的部分
import router from './router'
import AxiosPlugin from '@/utils/plugin/axios-plugin'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import 'viewerjs/dist/viewer.css'
  import Viewer from 'v-viewer'

Vue.config.productionTip = false

Vue.filter("timeFilter",function(value){
	console.log('timeFilter',value)
	if (!value) return '';
	const dateObj = new Date(value);
  // return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;

	var y=dateObj.getFullYear();
	var m=(dateObj.getMonth()+1).toString().padStart(2,"0");//不足两位补0,填充开始的部分
	var d=dateObj.getDate().toString().padStart(2,"0");
	// var h=currrentTime.getHours().toString().padStart(2,"0");
	// var mm=currrentTime.getMinutes().toString().padStart(2,"0");
	// var s=currrentTime.getSeconds().toString().padStart(2,"0");
	// return `${y}-${m}-${d}-${h}-${mm}-${s}`;//es6
	return `${y}-${m}-${d}`;//es6
});

Vue.use(Viewer,{
	defaultOptions:{
		'inline':false,
		'button':true, //右上角按钮
		"navbar": false, //底部缩略图
		"title": false, //当前图片标题
		"toolbar": true, //底部工具栏
		"tooltip": true, //显示缩放百分比
		"movable": true, //是否可以移动
		"zoomable": true, //是否可以缩放
		"rotatable": true, //是否可旋转
		"scalable": true, //是否可翻转
		"transition": true, //使用 CSS3 过度
		"fullscreen": true, //播放时是否全屏
		"keyboard": true, //是否支持键盘
	}
})

Vue.use(AxiosPlugin)
Vue.use(Antd)

new Vue({
  router,
  render: h => h(App),
  // router:router,注册路由，
  //下面直接一个router是简写(导入的router和注册的router名字一样，可以直接简写成一个router)
  
}).$mount('#app')
