<template>
  <div>
    <div class="topdiv">{{ entity.Company }}</div>

    <div class="divInfo">
      <a-divider type="vertical" class="divider1" />构件信息表
    </div>

    <div class="Descriptions">
      <div class="grid">
        <div class="row">
          <div class="title">项目名称</div>
          <div class="content">{{ entity.ProjectName }}</div>
        </div>
        <div class="row">
          <div class="title">楼号</div>
          <div class="content2">{{ entity.BuildingNo }}</div>
          <div class="title">楼层</div>
          <div class="content2">{{ entity.Floor }}</div>
        </div>

        <div class="row">
          <div class="title">设计型号</div>
          <div class="content">{{ entity.DesignModel }}</div>
        </div>
        <div class="row">
          <div class="title">产品编号</div>
          <div class="content">{{ entity.ProductNumber }}</div>
        </div>
        <div class="row">
          <div class="title">类型</div>
          <div class="content">{{ entity.Type }}</div>
        </div>

        <div class="row">
          <div class="title">方量</div>
          <div class="content2">{{ entity.Quantity }}</div>
          <div class="title">重量</div>
          <div class="content2">{{ entity.Weight }}</div>
        </div>

        <div class="row">
          <div class="title">构件图纸</div>
          <div class="content">
            <!-- canvas渲染方式 -->
            <!-- <router-link
              target="_blank"
              :to="{ path: '/ShowPdf', query: { file: entity.PdfUrl } }"
              v-if="entity.PdfUrl"
            > -->
            <div
              id="pdf"
              style="width: 140px"
              @click="jumpUrl()"
              v-if="entity.PdfUrl"
            >
              <pdf ref="pdf" :src="entity.PdfUrl"></pdf>
            </div>
            <!-- </router-link> -->
          </div>
        </div>
        <div class="rowBottom">
          <div class="title">构件模型</div>
          <div class="content">
            {{ entity.ComponentModel == "" ? "无模型" : entity.ComponentModel }}
          </div>
        </div>
      </div>
    </div>

    <div class="divInfo">
      <a-divider type="vertical" class="divider1" />生产过程记录
    </div>

    <div class="Descriptions">
      <div class="grid">
        <div class="row">
          <div class="title">计划时间</div>
          <div class="content">{{ entity.ScheduledTime | timeFilter }}</div>
        </div>
        <div class="row">
          <div class="title">实际生产日期</div>
          <div class="content">{{ entity.ManufactureDate | timeFilter }}</div>
        </div>

        <div class="row">
          <div class="title">隐检员</div>
          <div class="content">{{ entity.HiddenInspector }}</div>
        </div>
        <div class="rowBottom">
          <div class="title">隐检照片</div>
          <div class="content">
            <viewer
              :images="HiddenInspectorPhotoUrlList"
              v-if="entity.HiddenInspectorPhotoUrl"
            >
              <img
                v-for="src in HiddenInspectorPhotoUrlList"
                :src="src"
                :key="src"
                width="120"
                height="160"
              />
            </viewer>
          </div>
        </div>
      </div>
    </div>

    <div class="Descriptions">
      <div class="grid">
        <div class="row">
          <div class="title">浇筑日期</div>
          <div class="content">{{ entity.PouringDate | timeFilter }}</div>
        </div>
        <div class="row">
          <div class="title">脱模日期</div>
          <div class="content">{{ entity.DemoldingDate | timeFilter }}</div>
        </div>

        <div class="row">
          <div class="title">成检日期</div>
          <div class="content">
            {{ entity.CompletionInspectionDate | timeFilter }}
          </div>
        </div>
        <div class="row">
          <div class="title">成检员</div>
          <div class="content">{{ entity.Inspector }}</div>
        </div>
        <div class="row">
          <div class="title">成检照片</div>
          <div class="content">
            <viewer
              :images="InspectorPhotoUrlList"
              style="width: 100rpx; height: 100rpx"
              v-if="entity.InspectorPhotoUrl"
            >
              <img
                v-for="src in InspectorPhotoUrlList"
                :src="src"
                :key="src"
                width="120"
                height="160"
              />
            </viewer>
            <!-- <img
              decoding="async"
              :src="entity.InspectorPhotoUrl"
              alt="Smiley face"
              width="120"
              height="160"
              @click="ShowBigImg(entity.InspectorPhotoUrl)"
            /> -->
          </div>
        </div>
        <div class="rowBottom">
          <div class="title">检测报告</div>
          <div class="content">
            <viewer
              :images="HiddenInspectorPhotoUrlList"
              v-if="entity.TestReport"
            >
              <img
                v-for="src in TestReportUrlList"
                :src="src"
                :key="src"
                width="120"
                height="160"
              />
            </viewer>
          </div>
        </div>
      </div>
    </div>

    <div class="Descriptions">
      <div class="grid">
        <div class="row">
          <div class="title">入库日期</div>
          <div class="content">{{ entity.WarehousingDate | timeFilter }}</div>
        </div>
        <div class="row">
          <div class="title">入库员</div>
          <div class="content">{{ entity.WarehousingMan }}</div>
        </div>

        <div class="row">
          <div class="title">库房</div>
          <div class="content">{{ entity.StoreRoom }}</div>
        </div>
        <div class="rowBottom">
          <div class="title">库位</div>
          <div class="content">{{ entity.WarehouseLocation }}</div>
        </div>
      </div>
    </div>

    <div class="Descriptions">
      <div class="grid">
        <div class="row">
          <div class="title">出库日期</div>
          <div class="content">{{ entity.DeliveryDate | timeFilter }}</div>
        </div>
        <div class="row">
          <div class="title">出库人</div>
          <div class="content">{{ entity.DeliveryMan }}</div>
        </div>
        <div class="rowBottom">
          <div class="title">承运单位</div>
          <div class="content">{{ entity.Carrier }}</div>
        </div>
      </div>
    </div>
    <div class="Descriptions">
      <a href="https://beian.miit.gov.cn)">津ICP备2023004418号-1</a>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  name: "ShowInfo",
  props: {
    msg: String,
  },
  components: {
    pdf,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
      },
      visible: false,
      loading: false,
      entity: {},
      rules: {},
      title: "",
      PriceLis: [],
      size: "small",
      pdfurl: "Pdf/2023-06/123.pdf",
      InspectorPhotoUrlList: [],
      HiddenInspectorPhotoUrlList: [],
      TestReportUrlList: [],
    };
  },
  mounted: function () {
    let Id = this.$route.query.Id;
    console.log("id", Id);
    this.init(Id);
  },
  methods: {
    init(Id) {
      this.entity = {};
      this.$http
        .post("/BaseInfo_Manage/TblBaseInfo/GetTheData", { id: Id })
        .then((resJson) => {
          console.log(resJson);
          if (resJson.Success) {
            this.entity = resJson.Data;
            this.InspectorPhotoUrlList = [this.entity.InspectorPhotoUrl];
            this.HiddenInspectorPhotoUrlList = [
              this.entity.HiddenInspectorPhotoUrl,
            ];
            this.TestReportUrlList = [this.entity.TestReport];
          }
          console.log(this.entity.pdfUrl);
        });
    },
    jumpUrl() {
      // let path =
      //   window.location.protocol +
      //   "//zps.xhdpre.cn/pdfjs/web/viewer.html?file=" +
      //   this.entity.PdfUrl;
      // window.location.href = path;

      window.open(
        "https://zps.xhdpre.cn/pdfjs/web/viewer.html?file=" +
          this.entity.PdfUrl,
        "_blank"
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.topdiv {
  background-color: black;
  width: 100%;
  height: 60px;
  color: white;
  font-size: 20px;
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.divInfo {
  display: flex;
  justify-content: left;
  align-items: left;
  margin-top: 15px;
  margin-left: 3px;
  font-size: 16px;
}

.divider1 {
  height: 25px;
  width: 2px;
  color: dodgerblue;
  background-color: dodgerblue;
}

.Descriptions {
  background: #fff;
  padding: 10px;
}

.div {
  padding: 8px !important;
  color: #435780;
  border: 1px solid;
  border-color: #e0e0e0;
  border-right: 0;
  border-bottom: 0;
  display: table-cell;
}

.title {
  /* background-color: rgb(250, 250, 250); */
  background-color: #e2f3ff;
  min-height: 37.5px;
  color: #333;
  display: flex;
  justify-content: left;
  align-items: center;
  border-right: 1px solid #e0e0e0;
  /* width: calc(100% - 200px); */
  width: calc(100% / (24 / 6));
  font-size: 16px;
  padding: 10px;
}

.content {
  color: #2d3040;
  min-height: 37.5px;
  /* //20220531新增。解决内容为纯数字时不自动跳行导致宽度错位问题 */
  word-break: break-all;
  word-wrap: break-word;
  display: flex;
  justify-content: left;
  align-items: center;
  width: calc(100% / (24 / 18));
  font-size: 16px;
  padding: 6px;
}

.grid {
  width: 100%;
  border: 1.5px solid #71a8f0;
  display: table;
}

.row {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #b5d3e9;
}
.rowBottom {
  width: 100%;
  display: flex;
  /* border-bottom: 1px solid #b5d3e9; */
}
.content2 {
  color: #2d3040;
  min-height: 37.5px;
  /* //20220531新增。解决内容为纯数字时不自动跳行导致宽度错位问题 */
  word-break: break-all;
  word-wrap: break-word;
  display: flex;
  justify-content: left;
  align-items: center;
  width: calc(100% / (24 / 6));
  font-size: 16px;
  padding: 6px;
}
</style>
